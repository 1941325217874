<template>
    <div class="odds-payout" v-show="betslip_count > 0">
       <div class="odds-info"  data-toggle="modal"
       data-target="#showBetSlip">
 
           <span class="count">{{ betslip_count }}</span>
           <span class="title">
 
 
               Betslip
           </span>
           <div class="total-odds"> <span>Odds:</span> {{ odds | formatOdds }}</div>
       </div>
       
       <router-link to="/my-profile-bets" class="my-bets">
         My Bets
       </router-link>
          
     </div>
 </template>
 
 <script>
     export default {
         name: 'BetslipBottomModalToggle',
         props: {
             betslip_count: {
                 type: Number,
                 required: true
             
             },
             odds: {
                 type: Number,
                 required: true
             
             },
             payout: {
                 type: Number,
                 required: true
             
             }
         }
     }
 </script>
 
 <style scoped>
 .odds-info, .my-bets {
     background: var(--primary-bg-light);
     padding: 5px 10px;
     border-radius: 0.5rem;
     color: white;
     display: flex;
     align-items: center;
     gap: 0.5rem;
 
 }
 
 .odds-info {
    
     flex-grow: 1;
 }
 .title {
     flex-grow: 1;
 }
 
 .count {
     background: var(--yellow);
   color: var(--primary-dark);  
   width: 2rem;
   height: 2rem;
   border-radius: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0;
   font-size: 1.1rem;
   font-weight: bold;
 }
 
 .odds-payout {
   width: 100%;
   display: flex;
   align-items: stretch;
   padding: 1rem;
   background-color: var(--primary-dark);
   color: white;
   gap: 1rem;
 }
 
 .total-odds,
 .possible-payout {
   font-size: 14px;
 }
 
 .total-odds {
     display: flex;
     gap: 0.5rem;
     align-items: center;
 }
 
 .total-odds > span {
     font-size: 12px;
 }
 </style>